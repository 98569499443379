<template>
  <div>
    <van-loading type="spinner" v-if="loading" />
    <van-nav-bar
      :title="navbarTitle"
      left-text=""
      left-arrow
      :fixed="true"
      @click-left="back"
    />
    <div
      id="content"
      style="max-height: calc(100vh - 60px); overflow: hidden; padding: 0"
    >
      <van-tabs v-model="active" @click="tabClick">
        <van-tab :title="tab1">
          <div>
            <ul class="list" ref="homeUl">
              <li
                class="item"
                @click="chooseList(item.recordNo)"
                v-for="item in list"
                :key="item.id"
              >
                <p class="code">
                  <span class="number">{{ item.codeNo }}</span>
                  <!-- 蓝色 -->
                  <span class="money" style="color: #268eff" v-if="item.verifyStatus == 'ACTIVATED'&&
                      item.withdrawStatus == 'W'"
                    >+{{ item.points }}</span
                  >
                  <!-- 已提现 -->
                  <span
                    class="money"
                    style="color: #2dd070"
                    v-if="
                      item.verifyStatus == 'ACTIVATED' &&
                      item.withdrawStatus !== 'W'
                    "
                    >+{{ item.points }}</span
                  >
                  <span
                    class="failflag"
                    v-if="item.verifyStatus == 'ACTIVATED_FAIL'"
                  >
                    {{ $t("record.VerifyFail") }}</span
                  >
                  <span
                    class="doingflag"
                    v-if="item.verifyStatus == 'UNAUDITED'"
                  >
                    {{ $t("record.Tobeverified") }}</span
                  >
                </p>
                <p>{{ item.skuName }}</p>
                <p>{{ item.createTime }}</p>
              </li>
             <p class="nomore" v-if="nomoreData">{{ $t("mine.nomore") }}</p>
             
              <div class="none" v-if="list.length == 0">
                <div>
                  <img src="assets/nodata.png" />
                  <p>NO Data</p>
                </div>
              </div>
            </ul>
          </div>
        </van-tab>
        <van-tab :title="tab2">
          <div>
            <ul class="list" ref="homeUl2">
              <li
                class="item"
                @click="chooseList(item.recordNo)"
                v-for="item in list2"
                :key="item.id"
              >
                <p class="code">
                  <span class="number">{{ item.codeNo }}</span>
                  <span
                    class="doingflag"
                    v-if="item.verifyStatus == 'UNAUDITED'"
                  >
                    {{ $t("record.Tobeverified") }}</span
                  >
                </p>
                <p>{{ item.skuName }}</p>
                <p>{{ item.createTime }}</p>
              </li>
             <p class="nomore" v-if="nomoreData2">{{ $t("mine.nomore") }}</p>
              <div class="none" v-if="list2.length == 0">
                <div>
                  <img src="assets/nodata.png" />
                  <p>NO Data</p>
                </div>
              </div>
            </ul>
          </div>
        </van-tab>
        <van-tab :title="tab3">
          <div>
            <ul class="list" ref="homeUl3">
              <li
                class="item"
                @click="chooseList(item.recordNo)"
                v-for="item in list3"
                :key="item.id"
              >
                <p class="code">
                  <span class="number">{{ item.codeNo }}</span>
                  <span class="money"> +{{ item.points }}</span>
                </p>
                <p>{{ item.skuName }}</p>
                <p>{{ item.createTime }}</p>
              </li>
             <p class="nomore" v-if="nomoreData3">{{ $t("mine.nomore") }}</p>
              <div class="none" v-if="list3.length == 0">
                <div>
                  <img src="assets/nodata.png" />
                  <p>NO Data</p>
                </div>
              </div>
            </ul>
          </div>
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>
<script>
import { verifyQuery } from "@network/api";
export default {
  name: "",
  data() {
    return {
      nomoreData:false,
      nomoreData2:false,
      nomoreData3:false,
      loading: false,
      params: {
        size: 10,
        page: 1,
      },
      params2: {},
      params3: {},
      navbarTitle: "",
      tab1: "",
      tab2: "",
      tab3: "",
      items: [
        { index: 1, message: "" },
        { index: 2, message: "" },
        { index: 3, message: "" },
      ],
      list2: [],
      list3: [],
      list: [],
      active: 0,
      totalnum: 0,
      pagNum: 0,
      totalnum2: 0,
      pagNum2: 0,
      totalnum3: 0,
      pagNum3: 0,
    };
  },
  mounted() {
    this.navbarTitle = this.$t("record.title");
    this.tab1 = this.$t("record.tab1");
    this.tab2 = this.$t("record.tab2");
    this.tab3 = this.$t("record.tab3");
    this.fnInitData();
    //监听如果页面发生滚动时
    this.$nextTick(() => {
      this.$refs.homeUl.addEventListener("scroll", this.handleScroll, true);
    });
  },
  methods: {
    //页面滑到底部需要调用的方法
    handleScroll() {
      //下面这部分兼容手机端和PC端
      var scrollTop = this.$refs.homeUl.scrollTop; //滚动条的位置
      var windowHeitht = this.$refs.homeUl.clientHeight; //在页面上返回内容的可视高度
      var scrollHeight = this.$refs.homeUl.scrollHeight; //返回整个元素的高度（包括带滚动条的隐蔽的地方）
      //是否滚动到底部的判断
      if (Math.round(scrollTop) + windowHeitht == scrollHeight) {
        this.params.page++;
        if (this.pagNum <= this.totalnum) {
          this.loading = true;
          verifyQuery(this.params)
            .then((res) => {
              this.loading = false;
              if (res.code == "000000") {
                res.data.records.forEach((item) => {
                  this.list.push(item);
                });
                console.log(this.list);
                this.totalnum = res.data.pages;
                this.pagNum = res.data.page;
              } else {
                this.$toast(res.message);
              }
            })
            .catch((err) => {
              this.loading = false;
              this.$toast(err.message);
            });
        } else {
          this.nomoreData = true
          return;
        }
      }
    },
    handleScroll2() {
      //下面这部分兼容手机端和PC端
      var scrollTop = this.$refs.homeUl2.scrollTop; //滚动条的位置
      var windowHeitht = this.$refs.homeUl2.clientHeight; //在页面上返回内容的可视高度
      var scrollHeight = this.$refs.homeUl2.scrollHeight; //返回整个元素的高度（包括带滚动条的隐蔽的地方）
      //是否滚动到底部的判断
      if (Math.round(scrollTop) + windowHeitht == scrollHeight) {
        this.params2.page++;
        if (this.pagNum2 <= this.totalnum2) {
          this.loading = true;
          verifyQuery(this.params2)
            .then((res) => {
              this.loading = false;
              if (res.code == "000000") {
                res.data.records.forEach((item) => {
                  this.list2.push(item);
                });
                console.log(this.list2);
                this.totalnum2 = res.data.pages;
                this.pagNum2 = res.data.page;
              } else {
                this.$toast(res.message);
              }
            })
            .catch((err) => {
              this.loading = false;
              this.$toast(err.message);
            });
        } else {
          this.nomoreData2 = true
          return;
        }
      }
    },
    handleScroll3() {
      //下面这部分兼容手机端和PC端
      var scrollTop = this.$refs.homeUl3.scrollTop; //滚动条的位置
      var windowHeitht = this.$refs.homeUl3.clientHeight; //在页面上返回内容的可视高度
      var scrollHeight = this.$refs.homeUl3.scrollHeight; //返回整个元素的高度（包括带滚动条的隐蔽的地方）
      //是否滚动到底部的判断
      if (Math.round(scrollTop) + windowHeitht == scrollHeight) {
        this.params3.page++;
        if (this.pagNum2 <= this.totalnum3) {
          this.loading = true;
          verifyQuery(this.params3)
            .then((res) => {
              this.loading = false;
              if (res.code == "000000") {
                res.data.records.forEach((item) => {
                  this.list3.push(item);
                });
                console.log(this.list3);
                this.totalnum3 = res.data.pages; 
                this.pagNum3 = res.data.page;
              } else {
                this.$toast(res.message);
              }
            })
            .catch((err) => {
              this.loading = false;
              this.$toast(err.message);
            });
        } else {
          this.nomoreData3 = true
          return;
        }
      }
    },
    back() {
      // this.$router.back(-1);
            this.$router.replace('/mine' );
    },
    fnInitData() {
      this.loading = true;
      verifyQuery(this.params)
        .then((res) => {
          this.loading = false;
          if (res.code === "10011") {
            return;
          }
          if (res.code == "000000") {
            this.list = res.data.records;
            this.totalnum = res.data.pages;
            this.pagNum = res.data.page;
          } else {
            this.$toast(res.message);
          }
        })
        .catch((err) => {
          this.loading = false;
          this.$toast(err.message);
        });
    },
    chooseList(recordNo) {
      this.$router.push({
        name: "recordDetail",
        params: { recordNo: recordNo },
      });
      localStorage.setItem("recordNo", recordNo);
    },
    tabClick(num) {
      this.loading = true;
      if (num == 0) {
        this.params = {
          size: 10,
          page: 1,
        };
        verifyQuery(this.params)
          .then((res) => {
            this.loading = false;
            if (res.code === "10011") {
              return;
            }
            if (res.code == "000000") {
              this.list = res.data.records;
            } else {
              this.$toast(res.message);
            }
          })
          .catch((err) => {
            this.loading = false;
            this.$toast(err.message);
          });
      } else if (num == 1) {
        this.$nextTick(() => {
          this.$refs.homeUl2.addEventListener(
            "scroll",
            this.handleScroll2,
            true
          );
        });
        this.params2 = {
          size: 10,
          page: 1,
          verifyStatus: "UNAUDITED",
        };
        verifyQuery(this.params2)
          .then((res) => {
            this.loading = false;
            if (res.code === "10011") {
              return;
            }
            if (res.code == "000000") {
              this.list2 = res.data.records;
            } else {
              this.$toast(res.message);
            }
          })
          .catch((err) => {
            this.loading = false;
            this.$toast(err.message);
          });
      } else if (num == 2) {
        this.$nextTick(() => {
          this.$refs.homeUl3.addEventListener(
            "scroll",
            this.handleScroll3,
            true
          );
        });
        this.params3 = {
          size: 10,
          page: 1,
          withdrawStatus: "W",
        };
        verifyQuery(this.params3)
          .then((res) => {
            this.loading = false;
            if (res.code === "10011") {
              return;
            }
            if (res.code == "000000") {
              this.list3 = res.data.records;
            } else {
              this.$toast(res.message);
            }
          })
          .catch((err) => {
            this.loading = false;
            this.$toast(err.message);
          });
      }
    },
  },
};
</script>
<style scoped>
#content {
  box-sizing: border-box;
  padding: 0 20px;
  height: calc(100vh - 60px);
}
#content >>> .van-tabs--line .van-tabs__wrap {
  display: flex;
  justify-content: center;
}
.van-tab__text--ellipsis {
  font-size: 16px;
  line-height: 21px;
}
#content >>> .van-tabs__content {
  max-height: calc(100vh - 115px);
  overflow: hidden;
  padding: 0 20px;
}
#content >>> .van-tab--active {
  color: #2dd070;
}
#content >>> .van-tabs__line {
  background-color: #2dd070;
}
#content >>> .van-tab {
  padding: 0 10px;
  font-size: 14px;
  flex: unset;
}
.list {
  padding: 0;
  overflow: auto;
  height: calc(100vh - 115px);
}
.list .item {
  border-bottom: 1px solid #d5d5d5;
  position: relative;
}

.code .number {
  color: #333;
  font-size: 17px;
  line-height: 32px;
  font-weight: bold;
}

.item p {
  color: #999;
  font-size: 12px;
  line-height: 17px;
  min-height: 17px;
  margin: 9px 0;
}
.money{
  color: rgb(38, 142, 255);
right: 0;
  position: absolute;
  font-size: 17px;
  line-height: 32px;
  font-weight: bold;
}
.failflag {
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: #eb523d;
  right: 0;
  position: absolute;
}
.doingflag {
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: #268eff;
  right: 0;
  position: absolute;
}
.none{
  background: #fff;
}
</style>
